import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./Login";
import Home from "./User/Home";
import Doors from "./User/Doors";
import Users from "./User/Users";
import Logs from "./User/Logs";
import PendingFace from "./User/PendingFace";
import PendingRfid from "./User/PendingRfid";
import Rfid from "./User/Rfid";
import Groups from "./User/Groups";
import Organization from "./User/Organization";
import Places from "./User/Places";
import Hardware from "./User/Hardware";
import Account from "./User/Account";
import Logout from "./User/Logout";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style.css';

const App = () => {
  const userInfo = localStorage.getItem('userInfo');
  return (
    <BrowserRouter>
      {
        userInfo ? (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/doors" element={<Doors />} />
            <Route path="/users" element={<Users />} />
            <Route path="/access" element={<PendingFace />} />
            <Route path="/pending/face" element={<PendingFace />} />
            <Route path="/pending/rfid" element={<PendingRfid />} />
            <Route path="/logs" element={<Logs />} />
            <Route path="/rfid" element={<Rfid />} />
            <Route path="/groups" element={<Groups />} />
            <Route path="/organization" element={<Organization />} />
            <Route path="/places" element={<Places />} />
            <Route path="/hardware" element={<Hardware />} />
            <Route path="/account" element={<Account />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<Home />} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        )
      }
    </BrowserRouter>
  );
};

export default App;