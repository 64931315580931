import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const PendingRfid = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ pendingNotification, setPendingNotification ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [ rfids, setRfids ] = useState([]);
  const [ rfid, setRfid ] = useState({});
  const [ userAccess, setUserAccess ] = useState({});
  const [ showDetail, setShowDetail ] = useState(false);
  const [ loadingAssign, setLoadingAssign ] = useState(false);

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getPendingUsers();
      getRfids();
      getPendingNotification();
    }
  }, [userInfo]);

  const getPendingUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'pending/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      setUsers(dataUsers.values);
    }
  }

  const getRfids = async () => {
    const fetchRfids = await fetch(apiUrl + 'list/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataRfids = await fetchRfids.json();
    if (dataRfids.status === 200) {
      let listRfid = [];
      for (let u in dataRfids.values) {
        if (dataRfids.values[u].status === 'Available') {
          let data = dataRfids.values[u];
          data.value = dataRfids.values[u].access_key;
          data.label = dataRfids.values[u].access_key;
          listRfid.push(data);
        }
      }
      setRfids(listRfid);
    }
  }

  const getPendingNotification = async () => {    
    const fetchPendingNotification = await fetch(apiUrl + 'pending/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
      })
    });
    const dataPendingNotification = await fetchPendingNotification.json();
    if (dataPendingNotification.status === 200) {
      setPendingNotification(dataPendingNotification.values);
    }
  }

  const assignRfid = async (e) => {
    e.preventDefault();
    setLoadingAssign(true);
    const fetchRfid = await fetch(apiUrl + 'edit/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        rfidId: rfid.rfid_id,
        userId: userAccess.user_id,
        accessType: 'RFID',
        accessKey: rfid.access_key,
        status: 'Used'
      })
    });
    const dataRfid = await fetchRfid.json();
    if (dataRfid.status === 200) {
      window.location.reload();
    }
  }

  const handleDetailAccess = (userAccess) => {
    setRfid({});
    setUserAccess(userAccess);
    setShowDetail(true);
  }

  return (
    <div id="user-groups">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Access'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Access List</h3>
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <a className="nav-link" href="/pending/face">Pending Face {pendingNotification.length ? (<span className="text-danger ms-3">{pendingNotification.length}</span>) : ''}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="/pending/rfid">Pending RFID {users.length ? (<span className="text-danger ms-3">{users.length}</span>) : ''}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/logs">Access Log</a>
              </li>
            </ul>
            <div className="table-responsive">
              {users.length > 0 ? (
                <table className="table table-border table-hover">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Access Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, u) => (
                      <tr className="pointer" key={'access-log-' + u} onClick={() => handleDetailAccess(user)}>
                        <td>{user.user_name}</td>
                        <td>{user.access_type}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : ('No Data')}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Assign RFID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="assign" onSubmit={(e) => assignRfid(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>User</label></div>
              <div className="col-md-8">{userAccess.user_name}</div>
            </div>
            <div className="form-group mt-3">
              <h5>RFID</h5>
              <Select className="selection" options={rfids} value={rfid} onChange={(value) => setRfid(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
          <button className="btn btn-primary ms-3" type="submit" form="assign" disabled={loadingAssign}>{loadingAssign ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PendingRfid;
