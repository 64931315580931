import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const Groups = () => {
  const apiUrl = config.ENDPOINT;
  
  const [ userInfo, setUserInfo ] = useState({});
  const [ groups, setGroups ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [ doors, setDoors ] = useState([]);
  const [ groupUsers, setGroupUsers ] = useState([]);
  const [ groupDoors, setGroupDoors ] = useState([]);
  const [ group, setGroup ] = useState({});
  const [ showAdd, setShowAdd ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ loadingAdd, setLoadingAdd ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ addName, setAddName ] = useState('');
  const [ addDescription, setAddDescription ] = useState('');
  const [ addGroupUsers, setAddGroupUsers ] = useState([]);
  const [ addGroupDoors, setAddGroupDoors ] = useState([]);
  const [ editGroupId, setEditGroupId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editDescription, setEditDescription ] = useState('');
  const [ editOrganizationGroupId, setEditOrganizationGroupId ] = useState('');
  const [ search, setSearch ] = useState('');

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getGroups();
      getUsers();
      getDoors();
    }
  }, [userInfo]);

  const getGroups = async () => {
    const fetchGroups = await fetch(apiUrl + 'list/groups', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataGroups = await fetchGroups.json();
    if (dataGroups.status === 200) {
      let listGroups = [];
      dataGroups.values.map((dataGroup) => {
        dataGroup.search = dataGroup.name + " " + dataGroup.description;
        listGroups.push(dataGroup);
      });
      setGroups(listGroups);
    }
  }

  const getUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'list/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      let valueUsers = [];
      for (let g in dataUsers.values) {
        valueUsers.push({
          value: dataUsers.values[g].user_id,
          label: dataUsers.values[g].name
        });
      };
      setUsers(valueUsers);
    }
  }

  const getDoors = async () => {
    const fetchDoors = await fetch(apiUrl + 'list/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataDoors = await fetchDoors.json();
    if (dataDoors.status === 200) {
      let valueDoors = [];
      for (let d in dataDoors.values) {
        const doorIndex = valueDoors.findIndex(door => door.label === dataDoors.values[d].place_name);
        if (doorIndex >= 0) {
          valueDoors[doorIndex].options.push({
            value: dataDoors.values[d].door_id,
            label: dataDoors.values[d].name,
          });
        } else {
          valueDoors.push({
            label: dataDoors.values[d].place_name,
            options: [
              {
                value: dataDoors.values[d].door_id,
                label: dataDoors.values[d].name,
              }
            ]
          });
        }
      };
      setDoors(valueDoors);
    }
  }

  const getGroup = async (groupId) => {
    const fetchGroup = await fetch(apiUrl + 'get/group', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        groupId: groupId
      })
    });
    const dataGroup = await fetchGroup.json();
    if (dataGroup.status === 200) {
      setGroup(dataGroup.values);
    }
  }

  const getGroupUsers = async (groupId) => {
    const fetchGroupUsers = await fetch(apiUrl + 'get/group/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        groupId: groupId
      })
    });
    const dataGroupUsers = await fetchGroupUsers.json();
    if (dataGroupUsers.status === 200) {
      let valueGroupUsers = [];
      for (let ud in dataGroupUsers.values) {
        valueGroupUsers.push({
          value: dataGroupUsers.values[ud].user_id,
          label: dataGroupUsers.values[ud].user_name,
        });
      };
      setGroupUsers(valueGroupUsers);
    }
  }

  const getGroupDoors = async (groupId) => {
    const fetchGroupDoors = await fetch(apiUrl + 'get/group/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        groupId: groupId
      })
    });
    const dataGroupDoors = await fetchGroupDoors.json();
    if (dataGroupDoors.status === 200) {
      let valueGroupDoors = [];
      for (let ud in dataGroupDoors.values) {
        valueGroupDoors.push({
          value: dataGroupDoors.values[ud].door_id,
          label: dataGroupDoors.values[ud].name,
        });
      };
      setGroupDoors(valueGroupDoors);
    }
  }

  const addGroup = async (e) => {
    e.preventDefault();
    setLoadingAdd(true);
    const fetchGroup = await fetch(apiUrl + 'add/group', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        name: addName,
        description: addDescription,
        groupUsers: addGroupUsers,
        doorGroups: addGroupDoors
      })
    });
    const dataGroup = await fetchGroup.json();
    if (dataGroup.status === 200) {
      window.location.reload();
    }
  }

  const editGroup = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchGroup = await fetch(apiUrl + 'edit/group', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        groupId: editGroupId,
        name: editName,
        description: editDescription,
        organizationGroupId: editOrganizationGroupId,
        groupUsers: groupUsers,
        doorGroups: groupDoors
      })
    });
    const dataGroup = await fetchGroup.json();
    if (dataGroup.status === 200) {
      window.location.reload();
    }
  }

  const handleAddGroup = () => {
    setShowAdd(true);
  }

  const handleDetailGroup = (groupId) => {
    getGroup(groupId);
    getGroupUsers(groupId);
    getGroupDoors(groupId);
    setShowDetail(true);
  }

  const handleEditGroup = (group) => {
    setEditGroupId(group.group_id);
    setEditName(group.name);
    setEditDescription(group.description);
    setEditOrganizationGroupId(group.organization_id);
    setShowDetail(false);
    setShowEdit(true);
  }

  return (
    <div id="user-groups">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Groups'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Group List</h3>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <form id="search" onSubmit={(e) => e.preventDefault()}>
                <div className="input-group mb-0">
                  <input className="form-control" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                  <span className="btn btn-link"><i className="bi bi-search"/></span>
                </div>
              </form>
              <div>
                <button className="btn btn-secondary" onClick={() => handleAddGroup()}>Add</button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-border table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Users</th>
                    <th>Doors</th>
                  </tr>
                </thead>
                <tbody>
                  {groups.filter((group) => group.search.toLowerCase().includes(search.toLowerCase())).map((group, g) => (
                    <tr className="pointer" key={'group-' + g} onClick={() => handleDetailGroup(group.group_id)}>
                      <td>{group.name}</td>
                      <td>{group.description}</td>
                      <td>{group.users}</td>
                      <td>{group.doors}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add" onSubmit={(e) => addGroup(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addName} onChange={(e) => setAddName(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={addDescription} onChange={(e) => setAddDescription(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" options={users} isMulti={true} closeMenuOnSelect={false} value={addGroupUsers} onChange={(value) => setAddGroupUsers(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Doors</h5>
              <Select className="selection" options={doors} isMulti={true} closeMenuOnSelect={false} value={addGroupDoors} onChange={(value) => setAddGroupDoors(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowAdd(false)} disabled={loadingAdd}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="add" disabled={loadingAdd}>{loadingAdd ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Group Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">{group.name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">{group.description}</div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={groupUsers}/>
            </div>
            <div className="form-group mt-2">
              <h5>Doors</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={groupDoors}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
          <button className="btn btn-secondary ms-3" onClick={() => { handleEditGroup(group); }}>Edit</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editGroup(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={editDescription} onChange={(e) => setEditDescription(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" options={users} isMulti={true} closeMenuOnSelect={false} value={groupUsers} onChange={(value) => setGroupUsers(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Doors</h5>
              <Select className="selection" options={doors} isMulti={true} closeMenuOnSelect={false} value={groupDoors} onChange={(value) => setGroupDoors(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Groups;
