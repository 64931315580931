import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";

const Organization = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ organization, setOrganization ] = useState([]);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ editOrganizationId, setEditOrganizationId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editDescription, setEditDescription ] = useState('');

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    getOrganization();
  }, [userInfo]);

  const getOrganization = async () => {
    const fetchOrganization = await fetch(apiUrl + 'get/organization', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataOrganization = await fetchOrganization.json();
    if (dataOrganization.status === 200) {
      setOrganization(dataOrganization.values);
    }
  }

  const editOrganization = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchOrganization = await fetch(apiUrl + 'edit/organization', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: editOrganizationId,
        name: editName,
        description: editDescription
      })
    });
    const dataOrganization = await fetchOrganization.json();
    if (dataOrganization.status === 200) {
      window.location.reload();
    }
  }

  const handleEditOrganization = () => {
    setEditOrganizationId(organization.id);
    setEditName(organization.name);
    setEditDescription(organization.description);
    setShowEdit(true);
  }

  return (
    <div id="user-organization">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Organization'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mb-4">Organization Information</h3>
              {userInfo.role === 'Admin' ? (
                <div>
                  <button className="btn btn-secondary" onClick={() => handleEditOrganization()}>Edit</button>
                </div>
              ) : ''}
            </div>
            <div className="card">
              <div className="card-body p-4">
                <div className="">
                  <div className="row mt-2">
                    <div className="col-md-3"><strong>Name</strong></div>
                    <div className="col-md-9">{organization.name}</div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3"><strong>Description</strong></div>
                    <div className="col-md-9">{organization.description}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editOrganization(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={editDescription} onChange={(e) => setEditDescription(e.target.value)}/>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Organization;
