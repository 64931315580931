import React, { useState, useEffect } from "react";
import { config } from "../Components/Config";

const Sidebar = (props) => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ pendingNotification, setPendingNotification ] = useState(0);

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getPendingNotification();
    }
  }, [userInfo]);

  const getPendingNotification = async () => {    
    const fetchPendingNotification = await fetch(apiUrl + 'pending/notification', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
      })
    });
    const dataPendingNotification = await fetchPendingNotification.json();
    if (dataPendingNotification.status === 200) {
      setPendingNotification(dataPendingNotification.values);
    }
  }

  return (
    <div id="components-sidebar">
      <div className="sidebar py-5">
        <img className="d-block mx-auto" src="/logo.png" alt="Door Access"/>
        <div className="mt-5">
          {props.userInfo.role === "Admin" ? (
            <div className="sidebar-items px-5">
              <a className={props.active === 'Dashboard' ? 'active' : ''} href="/"><i className="bi bi-house-fill me-3"></i> Dashboard</a>
              <a className={props.active === 'Doors' ? 'active' : ''} href="/doors"><i className="bi bi-door-open-fill me-3"></i> Doors</a>
              <a className={props.active === 'Users' ? 'active' : ''} href="/users"><i className="bi bi-person-fill me-3"></i> Users</a>
              <a className={props.active === 'Access' ? 'active' : ''} href="/access"><i className="bi bi-key-fill me-3"></i> Access {pendingNotification ? (<span className={props.active === 'Access' ? "float-end" : "float-end text-danger"}>{pendingNotification}</span>) : ''}</a>
              <a className={props.active === 'Rfid' ? 'active' : ''} href="/rfid"><i className="bi bi-person-vcard-fill me-3"></i> Card</a>
              <a className={props.active === 'Groups' ? 'active' : ''} href="/groups"><i className="bi bi-people-fill me-3"></i> Groups</a>
              <a className={props.active === 'Places' ? 'active' : ''} href="/places"><i className="bi bi-geo-alt-fill me-3"></i> Places</a>
              <a className={props.active === 'Hardware' ? 'active' : ''} href="/hardware"><i className="bi bi-cpu-fill me-3"></i> Hardware</a>
              <a className={props.active === 'Organization' ? 'active' : ''} href="/organization"><i className="bi bi-building-fill me-3"></i> My Organization</a>
              <a className={props.active === 'My Account' ? 'active' : ''} href="/account"><i className="bi bi-person-vcard me-3"></i> My Account</a>
              <a href="/logout"><i className="bi bi-power me-3"></i> Logout</a>
            </div>
          ) : (
            <div className="sidebar-items px-5">
              <a className={props.active === 'Dashboard' ? 'active' : ''} href="/"><i className="bi bi-house-fill me-3"></i> Dashboard</a>
              <a className={props.active === 'Organization' ? 'active' : ''} href="/organization"><i className="bi bi-building-fill me-3"></i> My Organization</a>
              <a className={props.active === 'My Account' ? 'active' : ''} href="/account"><i className="bi bi-person-vcard me-3"></i> My Account</a>
              <a href="/logout"><i className="bi bi-power me-3"></i> Logout</a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
