import React, { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    const getUser = localStorage.getItem('userInfo');
    if (getUser) {
      localStorage.removeItem('userInfo');
    }
    window.location.replace('/');
  }, []);

  return (
    <div id="user-logout"></div>
  );
};

export default Logout;
