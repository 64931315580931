import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const Doors = () => {
  const apiUrl = config.ENDPOINT;
  
  const [ userInfo, setUserInfo ] = useState({});
  const [ doors, setDoors ] = useState([]);
  const [ places, setPlaces ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [ groups, setGroups ] = useState([]);
  const [ doorUsers, setDoorUsers ] = useState([]);
  const [ doorGroups, setDoorGroups ] = useState([]);
  const [ doorLogs, setDoorLogs ] = useState([]);
  const [ door, setDoor ] = useState({});
  const [ showAdd, setShowAdd ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ loadingAdd, setLoadingAdd ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ addPlaceId, setAddPlaceId ] = useState('');
  const [ addName, setAddName ] = useState('');
  const [ addFloor, setAddFloor ] = useState('');
  const [ addDescription, setAddDescription ] = useState('');
  const [ addDoorUsers, setAddDoorUsers ] = useState([]);
  const [ addDoorGroups, setAddDoorGroups ] = useState([]);
  const [ editDoorId, setEditDoorId ] = useState('');
  const [ editPlaceId, setEditPlaceId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editFloor, setEditFloor ] = useState('');
  const [ editDescription, setEditDescription ] = useState('');
  const [ search, setSearch ] = useState('');

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getDoors();
      getPlaces();
      getUsers();
      getGroups();
    }
  }, [userInfo]);

  const getDoors = async () => {
    const fetchDoors = await fetch(apiUrl + 'list/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    let dataDoors = await fetchDoors.json();
    if (dataDoors.status === 200) {
      let listDoors = [];
      dataDoors.values.map((dataDoor) => {
        dataDoor.search = dataDoor.name + " " + dataDoor.floor + " " + dataDoor.place_name;
        listDoors.push(dataDoor);
      });
      setDoors(listDoors);
    }
  }

  const getPlaces = async () => {
    const fetchPlaces = await fetch(apiUrl + 'list/places', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataPlaces = await fetchPlaces.json();
    if (dataPlaces.status === 200) {
      setPlaces(dataPlaces.values);
    }
  }

  const getUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'list/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      let valueUsers = [];
      for (let g in dataUsers.values) {
        valueUsers.push({
          value: dataUsers.values[g].user_id,
          label: dataUsers.values[g].name
        });
      };
      setUsers(valueUsers);
    }
  }

  const getGroups = async () => {
    const fetchGroups = await fetch(apiUrl + 'list/groups', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataGroups = await fetchGroups.json();
    if (dataGroups.status === 200) {
      let valueGroups = [];
      for (let g in dataGroups.values) {
        valueGroups.push({
          value: dataGroups.values[g].group_id,
          label: dataGroups.values[g].name
        });
      };
      setGroups(valueGroups);
    }
  }

  const getDoor = async (doorId) => {
    const fetchDoor = await fetch(apiUrl + 'get/door', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        doorId: doorId
      })
    });
    const dataDoor = await fetchDoor.json();
    if (dataDoor.status === 200) {
      setDoor(dataDoor.values);
    }
  }

  const getDoorUsers = async (doorId) => {
    const fetchDoorUsers = await fetch(apiUrl + 'get/door/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        doorId: doorId
      })
    });
    const dataDoorUsers = await fetchDoorUsers.json();
    if (dataDoorUsers.status === 200) {
      let valueDoorUsers = [];
      for (let du in dataDoorUsers.values) {
        valueDoorUsers.push({
          value: dataDoorUsers.values[du].user_id,
          label: dataDoorUsers.values[du].user_name,
        });
      };
      setDoorUsers(valueDoorUsers);
    }
  }

  const getDoorGroups = async (doorId) => {
    const fetchDoorGroups = await fetch(apiUrl + 'get/door/groups', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        doorId: doorId
      })
    });
    const dataDoorGroups = await fetchDoorGroups.json();
    if (dataDoorGroups.status === 200) {
      let valueDoorGroups = [];
      for (let du in dataDoorGroups.values) {
        valueDoorGroups.push({
          value: dataDoorGroups.values[du].group_id,
          label: dataDoorGroups.values[du].group_name,
        });
      };
      setDoorGroups(valueDoorGroups);
    }
  }

  const getDoorLogs = async (doorId) => {
    const fetchDoorLogs = await fetch(apiUrl + 'get/door/logs', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        doorId: doorId
      })
    });
    const dataDoorLogs = await fetchDoorLogs.json();
    if (dataDoorLogs.status === 200) {
      setDoorLogs(dataDoorLogs.values);
    }
  }

  const addDoor = async (e) => {
    e.preventDefault();
    setLoadingAdd(true);
    const fetchDoor = await fetch(apiUrl + 'add/door', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        placeId: addPlaceId,
        name: addName,
        floor: addFloor,
        description: addDescription,
        doorUsers: addDoorUsers,
        doorGroups: addDoorGroups
      })
    });
    const dataDoor = await fetchDoor.json();
    if (dataDoor.status === 200) {
      window.location.reload();
    }
  }

  const editDoor = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchDoor = await fetch(apiUrl + 'edit/door', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        doorId: editDoorId,
        placeId: editPlaceId,
        name: editName,
        floor: editFloor,
        description: editDescription,
        doorUsers: doorUsers,
        doorGroups: doorGroups
      })
    });
    const dataDoor = await fetchDoor.json();
    if (dataDoor.status === 200) {
      window.location.reload();
    }
  }

  const handleAddDoor = () => {
    setShowAdd(true);
  }

  const handleDetailDoor = (doorId) => {
    getDoor(doorId);
    getDoorUsers(doorId);
    getDoorGroups(doorId);
    getDoorLogs(doorId);
    setShowDetail(true);
  }

  const handleEditDoor = (door) => {
    setEditDoorId(door.door_id);
    setEditPlaceId(door.place_id);
    setEditName(door.name);
    setEditFloor(door.floor);
    setEditDescription(door.description);
    setShowDetail(false);
    setShowEdit(true);
  }
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  return (
    <div id="user-doors">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Doors'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Door List</h3>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <form id="search" onSubmit={(e) => e.preventDefault()}>
                <div className="input-group mb-0">
                  <input className="form-control" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                  <span className="btn btn-link"><i className="bi bi-search"/></span>
                </div>
              </form>
              <div>
                <button className="btn btn-secondary" onClick={() => handleAddDoor()}>Add</button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-border table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Place</th>
                    <th>Floor</th>
                    <th>Users</th>
                    <th>Groups</th>
                    <th>Last Access</th>
                  </tr>
                </thead>
                <tbody>
                  {doors.filter((door) => door.search.toLowerCase().includes(search.toLowerCase())).map((door, d) => (
                    <tr className="pointer" key={'door-' + d} onClick={() => handleDetailDoor(door.door_id)}>
                      <td>{door.name}</td>
                      <td>{door.place_name}</td>
                      <td>{door.floor}</td>
                      <td>{door.users ? door.users : 0}</td>
                      <td>{door.groups ? door.groups : 0}</td>
                      <td>{door.last_opened_on ? formatDate(door.last_opened_on) : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Door</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add" onSubmit={(e) => addDoor(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">
                <select className="form-select" value={addPlaceId} onChange={(e) => setAddPlaceId(e.target.value)} required>
                  <option value=""></option>
                  {places.map((place, p) => 
                    <option value={place.place_id} key={'place-' + p}>{place.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addName} onChange={(e) => setAddName(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Floor</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addFloor} onChange={(e) => setAddFloor(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={addDescription} onChange={(e) => setAddDescription(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" options={users} isMulti={true} closeMenuOnSelect={false} value={addDoorUsers} onChange={(value) => setAddDoorUsers(value)}/>
            </div>
            <div className="form-group mt-3">
              <h5>Groups</h5>
              <Select className="selection" options={groups} isMulti={true} closeMenuOnSelect={false} value={addDoorGroups} onChange={(value) => setAddDoorGroups(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowAdd(false)} disabled={loadingAdd}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="add" disabled={loadingAdd}>{loadingAdd ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Door Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">{door.place_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">{door.name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Floor</label></div>
              <div className="col-md-8">{door.floor}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">{door.description}</div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={doorUsers}/>
            </div>
            <div className="form-group mt-3">
              <h5>Groups</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={doorGroups}/>
            </div>
            {doorLogs.length > 0 ? (
              <div className="form-group mt-3">
                <h5>Access Log</h5>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Access Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {doorLogs.slice(0, 5).map((log, l) =>
                        <tr key={"log-" + l}>
                          <td>{formatDate(log.opened_on)}</td>
                          <td>{log.user_name}</td>
                          <td>{log.access_type}</td>
                          <td>{log.status === "Success" ? <span className="badge text-bg-success">Success</span> : <span className="badge text-bg-danger">Failed</span>}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {doorLogs.length >5 ? (
                    <a className="btn d-block bg-transparent" href={"/logs?filter=Door&value=" + door.door_id + "&label=" + door.name}>See more...</a>
                  ) : ''}
                </div>
              </div>
            ) : ''}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
          <button className="btn btn-secondary ms-3" onClick={() => { handleEditDoor(door); }}>Edit</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Door</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editDoor(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">
                <select className="form-select" value={editPlaceId} onChange={(e) => setEditPlaceId(e.target.value)} required>
                  <option value=""></option>
                  {places.map((place, p) => 
                    <option value={place.place_id} key={'place-' + p}>{place.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Floor</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editFloor} onChange={(e) => setEditFloor(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={editDescription} onChange={(e) => setEditDescription(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" options={users} isMulti={true} closeMenuOnSelect={false} value={doorUsers} onChange={(value) => setDoorUsers(value)}/>
            </div>
            <div className="form-group mt-3">
              <h5>Groups</h5>
              <Select className="selection" options={groups} isMulti={true} closeMenuOnSelect={false} value={doorGroups} onChange={(value) => setDoorGroups(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Doors;
