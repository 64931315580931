import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { config } from "./Components/Config";

const md5 = require('md5');

const Login = () => {
  const apiUrl = config.ENDPOINT;

  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ formError, setFormError ] = useState('');

  const checkLogin = async (e) => {
    e.preventDefault();
    setFormError('');

    const getUser = await fetch(apiUrl + 'check/login', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        username: username,
        password: md5(password)
      })
    });

    const dataUser = await getUser.json();
    if (dataUser.status === 200) {
      localStorage.setItem('userInfo', JSON.stringify({
        userId: dataUser.values.user_id,
        name: dataUser.values.name,
        email: dataUser.values.email,
        role: dataUser.values.role,
        organizationId: dataUser.values.organization_id,
        organizationName: dataUser.values.organization_name,
        organizationDescription: dataUser.values.organization_description
      }));
      
      window.location.replace('/');
    } else if (dataUser.status === 401) {
      setFormError(dataUser.values);
    }
  }

  return (
    <div id="guest-login">
      <Container fluid>
        <div className="row vh-100">
          <div className="col-md-5 d-flex align-items-center justify-content-center bg-login">
            <div className="login-left">
              <strong className="d-block mb-2 text-global">Door Access</strong>
              <p className="mb-5">Open doors to secure access - Login now and step into a world of safety.</p>
              <img className="w-100" src="/login-bg.png" alt="Login"/>
            </div>
          </div>
          <div className="col-md-7 d-flex align-items-center justify-content-center">
            <div className="login-right">
              <div className="card border-0">
                <div className="card-header border-0 bg-transparent text-center">
                  <img className="mb-2" src="/emblem.png" alt="Emblem" style={{width: '90px'}}/>
                  <h2><span className="text-global">Door</span> Access</h2>
                </div>
                <div className="card-body">
                  <form action="" method="post" onSubmit={(e) => checkLogin(e)}>
                    <div className="form-group">
                      <label>Username</label>
                      <input className="form-control" type="text" value={username} onChange={(e) => setUsername(e.target.value)} required/>
                    </div>
                    <div className="form-group mt-2">
                      <label>Password</label>
                      <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    </div>
                    {formError && (
                      <small className="d-block text-danger mt-2">{formError}</small>
                    )}
                    <button className="btn btn-primary w-100 mt-2" type="submit">Login</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
