import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const Places = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ places, setPlaces ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [ placeUsers, setPlaceUsers ] = useState([]);
  const [ placeDoors, setPlaceDoors ] = useState([]);
  const [ place, setPlace ] = useState({});
  const [ showAdd, setShowAdd ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ loadingAdd, setLoadingAdd ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ addName, setAddName ] = useState('');
  const [ addDescription, setAddDescription ] = useState('');
  const [ addPlaceUsers, setAddPlaceUsers ] = useState([]);
  const [ editPlaceId, setEditPlaceId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editDescription, setEditDescription ] = useState('');
  const [ editOrganizationPlaceId, setEditOrganizationPlaceId ] = useState('');
  const [ search, setSearch ] = useState('');

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getPlaces();
      getUsers();
    }
  }, [userInfo]);

  const getPlaces = async () => {
    const fetchPlaces = await fetch(apiUrl + 'list/places', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataPlaces = await fetchPlaces.json();
    if (dataPlaces.status === 200) {
      let listPlaces = [];
      dataPlaces.values.map((dataPlace) => {
        dataPlace.search = dataPlace.name + " " + dataPlace.description;
        listPlaces.push(dataPlace);
      });
      setPlaces(listPlaces);
    }
  }

  const getUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'list/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      let valueUsers = [];
      for (let g in dataUsers.values) {
        valueUsers.push({
          value: dataUsers.values[g].user_id,
          label: dataUsers.values[g].name
        });
      };
      setUsers(valueUsers);
    }
  }

  const getPlace = async (placeId) => {
    const fetchPlace = await fetch(apiUrl + 'get/place', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        placeId: placeId
      })
    });
    const dataPlace = await fetchPlace.json();
    if (dataPlace.status === 200) {
      setPlace(dataPlace.values);
    }
  }

  const getPlaceUsers = async (placeId) => {
    const fetchPlaceUsers = await fetch(apiUrl + 'get/place/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        placeId: placeId
      })
    });
    const dataPlaceUsers = await fetchPlaceUsers.json();
    if (dataPlaceUsers.status === 200) {
      let valuePlaceUsers = [];
      for (let ud in dataPlaceUsers.values) {
        valuePlaceUsers.push({
          value: dataPlaceUsers.values[ud].user_id,
          label: dataPlaceUsers.values[ud].user_name,
        });
      };
      setPlaceUsers(valuePlaceUsers);
    }
  }

  const getPlaceDoors = async (placeId) => {
    const fetchPlaceDoors = await fetch(apiUrl + 'get/place/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        placeId: placeId
      })
    });
    const dataPlaceDoors = await fetchPlaceDoors.json();
    if (dataPlaceDoors.status === 200) {
      let valuePlaceDoors = [];
      for (let ud in dataPlaceDoors.values) {
        valuePlaceDoors.push({
          value: dataPlaceDoors.values[ud].id,
          label: dataPlaceDoors.values[ud].name,
        });
      };
      setPlaceDoors(valuePlaceDoors);
    }
  }

  const addPlace = async (e) => {
    e.preventDefault();
    setLoadingAdd(true);
    const fetchPlace = await fetch(apiUrl + 'add/place', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        name: addName,
        description: addDescription,
        placeUsers: addPlaceUsers
      })
    });
    const dataPlace = await fetchPlace.json();
    if (dataPlace.status === 200) {
      window.location.reload();
    }
  }

  const editPlace = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchPlace = await fetch(apiUrl + 'edit/place', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        placeId: editPlaceId,
        name: editName,
        description: editDescription,
        organizationPlaceId: editOrganizationPlaceId,
        placeUsers: placeUsers
      })
    });
    const dataPlace = await fetchPlace.json();
    if (dataPlace.status === 200) {
      window.location.reload();
    }
  }

  const handleAddPlace = () => {
    setShowAdd(true);
  }

  const handleDetailPlace = (placeId) => {
    getPlace(placeId);
    getPlaceUsers(placeId);
    getPlaceDoors(placeId);
    setShowDetail(true);
  }

  const handleEditPlace = (place) => {
    setEditPlaceId(place.place_id);
    setEditName(place.name);
    setEditDescription(place.description);
    setEditOrganizationPlaceId(place.organization_id);
    setShowDetail(false);
    setShowEdit(true);
  }

  return (
    <div id="user-places">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Places'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Place List</h3>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <form id="search" onSubmit={(e) => e.preventDefault()}>
                <div className="input-group mb-0">
                  <input className="form-control" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                  <span className="btn btn-link"><i className="bi bi-search"/></span>
                </div>
              </form>
              <div>
                <button className="btn btn-secondary" onClick={() => handleAddPlace()}>Add</button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-border table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Users</th>
                    <th>Doors</th>
                  </tr>
                </thead>
                <tbody>
                  {places.filter((place) => place.search.toLowerCase().includes(search.toLowerCase())).map((place, p) => (
                    <tr className="pointer" key={'place-' + p} onClick={() => handleDetailPlace(place.place_id)}>
                      <td>{place.name}</td>
                      <td>{place.description}</td>
                      <td>{place.users ? place.users : 0}</td>
                      <td>{place.doors ? place.doors : 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Place</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add" onSubmit={(e) => addPlace(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addName} onChange={(e) => setAddName(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={addDescription} onChange={(e) => setAddDescription(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" options={users} isMulti={true} closeMenuOnSelect={false} value={addPlaceUsers} onChange={(value) => setAddPlaceUsers(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowAdd(false)} disabled={loadingAdd}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="add" disabled={loadingAdd}>{loadingAdd ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Place Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">{place.name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">{place.description}</div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={placeUsers}/>
            </div>
            <div className="form-group mt-3">
              <h5>Doors</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={placeDoors}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
          <button className="btn btn-secondary ms-3" onClick={() => { handleEditPlace(place); }}>Edit</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Place</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editPlace(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={editDescription} onChange={(e) => setEditDescription(e.target.value)}/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Users</h5>
              <Select className="selection" options={users} isMulti={true} closeMenuOnSelect={false} value={placeUsers} onChange={(value) => setPlaceUsers(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Places;
