import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";

const Hardware = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ hardwares, setHardwares ] = useState([]);
  const [ places, setPlaces ] = useState([]);
  const [ doors, setDoors ] = useState([]);
  const [ hardware, setHardware ] = useState({});
  const [ showAdd, setShowAdd ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ loadingAdd, setLoadingAdd ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ addPlaceId, setAddPlaceId ] = useState('');
  const [ addDoorId, setAddDoorId ] = useState('');
  const [ addName, setAddName ] = useState('');
  const [ addDescription, setAddDescription ] = useState('');
  const [ addIpAddress, setAddIpAddress ] = useState('');
  const [ addSerialNumber, setAddSerialNumber ] = useState('');
  const [ editHardwareId, setEditHardwareId ] = useState('');
  const [ editPlaceId, setEditPlaceId ] = useState('');
  const [ editDoorId, setEditDoorId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editDescription, setEditDescription ] = useState('');
  const [ editIpAddress, setEditIpAddress ] = useState('');
  const [ editSerialNumber, setEditSerialNumber ] = useState('');
  const [ search, setSearch ] = useState('');

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getHardwares();
      getPlaces();
      getDoors();
    }
  }, [userInfo]);

  const getHardwares = async () => {
    const fetchHardware = await fetch(apiUrl + 'list/hardware', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataHardwares = await fetchHardware.json();
    if (dataHardwares.status === 200) {
      let listHardwares = [];
      dataHardwares.values.map((dataHardware) => {
        dataHardware.search = dataHardware.name + " " + dataHardware.ip_address + " " + dataHardware.serial_number + " " + dataHardware.place_name + " " + dataHardware.door_name;
        listHardwares.push(dataHardware);
      });
      setHardwares(listHardwares);
    }
  }

  const getPlaces = async () => {
    const fetchPlaces = await fetch(apiUrl + 'list/places', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataPlaces = await fetchPlaces.json();
    if (dataPlaces.status === 200) {
      setPlaces(dataPlaces.values);
    }
  }

  const getDoors = async () => {
    const fetchDoors = await fetch(apiUrl + 'list/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });

    const dataDoors = await fetchDoors.json();
    if (dataDoors.status === 200) {
      setDoors(dataDoors.values);
    }
  }

  const getHardware = async (hardwareId) => {
    const fetchHardware = await fetch(apiUrl + 'get/hardware', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        hardwareId: hardwareId
      })
    });
    const dataHardware = await fetchHardware.json();
    if (dataHardware.status === 200) {
      setHardware(dataHardware.values);
    }
  }

  const addHardware = async (e) => {
    e.preventDefault();
    setLoadingAdd(true);
    const fetchHardware = await fetch(apiUrl + 'add/hardware', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        placeId: addPlaceId,
        doorId: addDoorId,
        name: addName,
        description: addDescription,
        ipAddress: addIpAddress,
        serialNumber: addSerialNumber
      })
    });
    const dataHardware = await fetchHardware.json();
    if (dataHardware.status === 200) {
      window.location.reload();
    }
  }

  const editHardware = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchHardware = await fetch(apiUrl + 'edit/hardware', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        hardwareId: editHardwareId,
        placeId: editPlaceId,
        doorId: editDoorId,
        name: editName,
        ipAddress: editIpAddress,
        serialNumber: editSerialNumber,
        description: editDescription
      })
    });
    const dataHardware = await fetchHardware.json();
    if (dataHardware.status === 200) {
      window.location.reload();
    }
  }

  const handleAddHardware = () => {
    setShowAdd(true);
  }

  const handleDetailHardware = (hardwareId) => {
    getHardware(hardwareId);
    setShowDetail(true);
  }

  const handleEditHardware = (hardware) => {
    setEditHardwareId(hardware.hardware_id);
    setEditPlaceId(hardware.place_id);
    setEditDoorId(hardware.door_id);
    setEditName(hardware.name);
    setEditDescription(hardware.description);
    setEditIpAddress(hardware.ip_address);
    setEditSerialNumber(hardware.serial_number);
    setShowDetail(false);
    setShowEdit(true);
  }

  return (
    <div id="user-hardware">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Hardware'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Hardware List</h3>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <form id="search" onSubmit={(e) => e.preventDefault()}>
                <div className="input-group mb-0">
                  <input className="form-control" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                  <span className="btn btn-link"><i className="bi bi-search"/></span>
                </div>
              </form>
              <div>
                <button className="btn btn-secondary" onClick={() => handleAddHardware()}>Add</button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-border table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>IP Address</th>
                    <th>S/N</th>
                    <th>Place</th>
                    <th>Doors</th>
                  </tr>
                </thead>
                <tbody>
                  {hardwares.filter((hardware) => hardware.search.toLowerCase().includes(search.toLowerCase())).map((hardware, h) => (
                    <tr className="pointer" key={'hardware-' + h} onClick={() => handleDetailHardware(hardware.hardware_id)}>
                      <td>{hardware.name}</td>
                      <td>{hardware.ip_address}</td>
                      <td>{hardware.serial_number}</td>
                      <td>{hardware.place_name}</td>
                      <td>{hardware.door_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Hardware</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add" onSubmit={(e) => addHardware(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">
                <select className="form-select" value={addPlaceId} onChange={(e) => setAddPlaceId(e.target.value)} required>
                  <option value=""></option>
                  {places.map((place, p) => 
                    <option value={place.place_id} key={'place-' + p}>{place.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Door</label></div>
              <div className="col-md-8">
                <select className="form-select" value={addDoorId} onChange={(e) => setAddDoorId(e.target.value)}>
                  <option value=""></option>
                  {doors.map((door, d) => 
                    <option value={door.door_id} key={'door-' + d}>{door.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addName} onChange={(e) => setAddName(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>IP Address</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addIpAddress} onChange={(e) => setAddIpAddress(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Serial Number</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addSerialNumber} onChange={(e) => setAddSerialNumber(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={addDescription} onChange={(e) => setAddDescription(e.target.value)}/>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowAdd(false)} disabled={loadingAdd}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="add" disabled={loadingAdd}>{loadingAdd ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Hardware Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">{hardware.place_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Door</label></div>
              <div className="col-md-8">{hardware.door_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">{hardware.name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>IP Address</label></div>
              <div className="col-md-8">{hardware.ip_address}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Serial Number</label></div>
              <div className="col-md-8">{hardware.serial_number}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">{hardware.description}</div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
          <button className="btn btn-secondary ms-3" onClick={() => { handleEditHardware(hardware); }}>Edit</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Hardware</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editHardware(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">
                <select className="form-select" value={editPlaceId} onChange={(e) => setEditPlaceId(e.target.value)} required>
                  <option value=""></option>
                  {places.map((place, p) => 
                    <option value={place.place_id} key={'place-' + p}>{place.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Door</label></div>
              <div className="col-md-8">
                <select className="form-select" value={editDoorId} onChange={(e) => setEditDoorId(e.target.value)}>
                  <option value=""></option>
                  {doors.map((door, d) => 
                    <option value={door.door_id} key={'door-' + d}>{door.name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>IP Address</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editIpAddress} onChange={(e) => setEditIpAddress(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Serial Number</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editSerialNumber} onChange={(e) => setEditSerialNumber(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Description</label></div>
              <div className="col-md-8">
                <textarea className="form-control" value={editDescription} onChange={(e) => setEditDescription(e.target.value)}/>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Hardware;
