import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";

const Logs = () => {
  const apiUrl = config.ENDPOINT;
  
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const getFilter = params.get('filter');
  const getValue = params.get('value');
  const getLabel = params.get('label');
  const filters = [{label: 'Door', value: 'Door'}, {label: 'User', value: 'User'}];
  const [ filter, setFilter ] = useState(getFilter ? {label: getFilter, value: getFilter} : {});
  const [ value, setValue ] = useState(getValue ? {label: getLabel, value: parseInt(getValue)} : {});

  const [ userInfo, setUserInfo ] = useState({});
  const [ accessLogs, setAccessLogs ] = useState([]);
  const [ doors, setDoors ] = useState([]);
  const [ users, setUsers ] = useState([]);
  const [ accessLog, setAccessLog ] = useState({});
  const [ showDetail, setShowDetail ] = useState(false);

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getDoors();
      getUsers();
    }
  }, [userInfo]);

  useEffect(() => {
    if (filter && value) {
      if (filter.value && value.value) {
        getAccessLogs();
      }
    }
  }, [filter, value]);

  const getAccessLogs = async () => {
    const fetchAccessLogs = await fetch(apiUrl + 'list/logs', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        filter: filter.value,
        value: value.value
      })
    });
    const dataAccessLogs = await fetchAccessLogs.json();
    if (dataAccessLogs.status === 200) {
      setAccessLogs(dataAccessLogs.values);      
    }
  }

  const handleDetailLog = (accessLog) => {
    setAccessLog(accessLog);
    setShowDetail(true);
  }

  const getDoors = async () => {
    const fetchDoors = await fetch(apiUrl + 'list/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataDoors = await fetchDoors.json();
    if (dataDoors.status === 200) {
      let valueDoors = [];
      for (let d in dataDoors.values) {
        const doorIndex = valueDoors.findIndex(door => door.label === dataDoors.values[d].place_name);
        if (doorIndex >= 0) {
          valueDoors[doorIndex].options.push({
            value: dataDoors.values[d].door_id,
            label: dataDoors.values[d].name,
          });
        } else {
          valueDoors.push({
            label: dataDoors.values[d].place_name,
            options: [
              {
                value: dataDoors.values[d].door_id,
                label: dataDoors.values[d].name,
              }
            ]
          });
        }
      };
      setDoors(valueDoors);
    }
  }

  const getUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'list/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      let valueUsers = [];
      for (let g in dataUsers.values) {
        valueUsers.push({
          value: dataUsers.values[g].user_id,
          label: dataUsers.values[g].name
        });
      };
      setUsers(valueUsers);
    }
  }

  const handleFilter = (key) => {
    setFilter(key);
    setValue({});
  }

  const handleExport = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();
    worksheet.columns = [      
      {header: 'No.', key: 'number'},
      {header: 'Place', key: 'place_name'},
      {header: 'Floor', key: 'door_floor'},
      {header: 'Door Name', key: 'door_name'},
      {header: 'User Name', key: 'user_name'},
      {header: 'Access Type', key: 'access_type'},
      {header: 'Access Key', key: 'access_key'},
      {header: 'Access Time', key: 'opened_on'},
      {header: 'Status', key: 'status'}
    ];
    let number = 0;
    let contents = [];
    for (let aL in accessLogs) {
      number++;
      let content = {
        number: number,
        place_name: accessLogs[aL].place_name,
        door_floor: accessLogs[aL].door_floor,
        door_name: accessLogs[aL].door_name,
        user_name: accessLogs[aL].user_name,
        access_type: accessLogs[aL].access_type,
        access_key: accessLogs[aL].access_key,
        opened_on: formatDate(accessLogs[aL].opened_on),
        status: accessLogs[aL].status
      }
      contents.push(content);
    }
    worksheet.getRow(1).height = 30;
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.addRows(contents);    
    worksheet.eachRow(function(row, rowNumber) {
      row.eachCell(function(cell, colNumber) {
        const column = worksheet.getColumn(colNumber);
        const maxLength = cell.value ? cell.value.toString().length : 10;
        if (column.width < maxLength) {
          column.width = maxLength < 15 ? 15 : maxLength; 
        }
      });
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
      saveAs(blob, 'Log List.xlsx');
    });
  }
  
  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Intl.DateTimeFormat('en-GB', options).format(date);
    }
    return;
  }

  return (
    <div id="user-groups">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Access'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Access List</h3>
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <a className="nav-link" href="/pending/face">Pending Face</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/pending/rfid">Pending RFID</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="/logs">Access Log</a>
              </li>
            </ul>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <form className="d-flex gap-2" id="filter" onSubmit={(e) => getAccessLogs(e)}>
                <div className="form-group mb-0">
                  <Select className="selection" options={filters} value={filter} placeholder={"Filter..."} onChange={(value) => handleFilter(value)} required/>
                </div>
                <div className="form-group mb-0">
                  <Select className="selection" options={filter.value === "Door" ? doors : users} value={value} placeholder={"Value..."} onChange={(value) => setValue(value)} required/>
                </div>
              </form>              
              <div>
                <button className="btn btn-secondary" onClick={() => handleExport()}>Export</button>
              </div>
            </div>
            <div className="table-responsive">
              {accessLogs.length > 0 ? (
                <table className="table table-border table-hover">
                  <thead>
                    <tr>
                      <th>Opened On</th>
                      <th>Door</th>
                      <th>User</th>
                      <th>Type</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accessLogs.map((accessLog, al) => (
                      <tr className="pointer" key={'access-log-' + al} onClick={() => handleDetailLog(accessLog)}>
                        <td>{formatDate(accessLog.opened_on)}</td>
                        <td>{accessLog.door_name}</td>
                        <td>{accessLog.user_name}</td>
                        <td>{accessLog.access_type}</td>
                        <td>{accessLog.status === "Success" ? <span className="badge text-bg-success">Success</span> : <span className="badge text-bg-danger">Failed</span>}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : ('No Data')}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Log Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>User</label></div>
              <div className="col-md-8">{accessLog.user_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Door</label></div>
              <div className="col-md-8">{accessLog.door_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Place</label></div>
              <div className="col-md-8">{accessLog.place_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Floor</label></div>
              <div className="col-md-8">{accessLog.door_floor}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Opened On</label></div>
              <div className="col-md-8">{formatDate(accessLog.opened_on)}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Status</label></div>
              <div className="col-md-8">{accessLog.status}</div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Logs;
