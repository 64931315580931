import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Papa from "papaparse";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";

const Rfid = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ users, setUsers ] = useState([]);
  const [ user, setUser ] = useState(null);
  const [ rfids, setRfids ] = useState([]);
  const [ rfid, setRfid ] = useState({});
  const [ addAccessKey, setAddAccessKey ] = useState('');
  const [ addUser, setAddUser ] = useState({});
  const [ importFile, setImportFile ] = useState(null);
  const [ importData, setImportData ] = useState([]);
  const [ editRfidId, setEditRfidId ] = useState('');
  const [ editAccessKey, setEditAccessKey ] = useState('');
  const [ editUser, setEditUser ] = useState({});
  const [ showAdd, setShowAdd ] = useState(false);
  const [ showImport, setShowImport ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ loadingAdd, setLoadingAdd ] = useState(false);
  const [ loadingImport, setLoadingImport ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ display, setDisplay ] = useState('');

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getRfids();
      getUsers();
    }
  }, [userInfo]);

  useEffect(() => {
    getRfids();
  }, [display]);

  const getRfids = async () => {
    const fetchRfids = await fetch(apiUrl + 'list/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataRfids = await fetchRfids.json();
    if (dataRfids.status === 200) {
      let listRfids = [];
      for( let u in dataRfids.values) {
        dataRfids.values[u].search = dataRfids.values[u].access_key + " " + dataRfids.values[u].status + " " + dataRfids.values[u].user_name + " " + dataRfids.values[u].taken_name;
        if (display) {
          if (dataRfids.values[u].status === display) {
            listRfids.push(dataRfids.values[u]);
          }
        } else {
          listRfids.push(dataRfids.values[u]);
        }
      }
      setRfids(listRfids);
    }
  }

  const getUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'pending/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      setUsers(dataUsers.values);
      let valueUsers = [];
      for (let u in dataUsers.values) {
        valueUsers.push({
          value: dataUsers.values[u].user_id,
          label: dataUsers.values[u].user_name
        });
      };
      setUsers(valueUsers);
    }
  }

  const getRfid = async (rfidId) => {
    const fetchRfid = await fetch(apiUrl + 'get/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        rfidId: rfidId
      })
    });
    const dataRfid = await fetchRfid.json();
    if (dataRfid.status === 200) {
      setUser(null);
      setRfid(dataRfid.values);
      if (dataRfid.values.taken_id) {
        setUser({
          value: dataRfid.values.taken_id,
          label: dataRfid.values.taken_name
        })
      }
    }
  }

  const getImportFile = (file) => {
    setImportFile(file);
    setImportData([]);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function(accessKeys) {
        let keys = [];
        for (let ak in accessKeys.data) {
          if (accessKeys.data[ak].RFID !== '') {
            keys.push(accessKeys.data[ak].RFID);
          }
        }
        setImportData(...importData, keys);
      }
    });
  }

  const addRfid = async (e) => {
    e.preventDefault();
    setLoadingAdd(true);
    const fetchRfid = await fetch(apiUrl + 'add/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        createdBy: userInfo.userId,
        userId: addUser.value ? addUser.value : '',
        accessType: 'RFID',
        accessKey: addAccessKey,
        status: addUser.value ? 'Used' : 'Available'
      })
    });
    const dataRfid = await fetchRfid.json();
    if (dataRfid.status === 200) {
      window.location.reload();
    }
  }

  const importRfid = async (e) => {
    e.preventDefault();
    setLoadingImport(true);
    const fetchRfid = await fetch(apiUrl + 'import/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        createdBy: userInfo.userId,
        accessKeys: importData,
        status: 'Available'
      })
    });
    const dataRfid = await fetchRfid.json();
    if (dataRfid.status === 200) {
      window.location.reload();
    }
  }

  const editRfid = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchRfid = await fetch(apiUrl + 'edit/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        rfidId: editRfidId,
        userId: editUser.value ? editUser.value : (rfid.taken_id ? rfid.taken_id : ''),
        accessType: 'RFID',
        accessKey: editAccessKey,
        status: editUser.value ? 'Used' : (rfid.taken_id ? 'Used' : 'Available')
      })
    });
    const dataRfid = await fetchRfid.json();
    if (dataRfid.status === 200) {
      window.location.reload();
    }
  }

  const revokeRfid = async () => {
    const fetchRfid = await fetch(apiUrl + 'revoke/rfid', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        rfidId: rfid.rfid_id,
        userId: rfid.taken_id,
        accessType: 'RFID'
      })
    });
    const dataRfid = await fetchRfid.json();
    if (dataRfid.status === 200) {
      window.location.reload();
    }
  }
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  }

  const handleAddRfid = () => {
    setShowAdd(true);
  }

  const handleImportRfid = () => {
    setShowImport(true);
  }

  const handleExportRfid = () => {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet();
    worksheet.columns = [      
      {header: 'No.', key: 'number'},
      {header: 'Key', key: 'access_key'},
      {header: 'Used By', key: 'taken_name'},
      {header: 'Created On', key: 'created_on'},
      {header: 'Status', key: 'status'}
    ];
    let number = 0;
    let contents = [];
    for (let r in rfids) {
      number++;
      let content = {
        number: number,
        access_key: rfids[r].access_key,
        taken_name: rfids[r].taken_name,
        created_on: formatDate(rfids[r].created_on),
        status: rfids[r].status
      }
      contents.push(content);
    }
    worksheet.getRow(1).height = 30;
    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.addRows(contents);    
    worksheet.eachRow(function(row, rowNumber) {
      row.eachCell(function(cell, colNumber) {
        const column = worksheet.getColumn(colNumber);
        const maxLength = cell.value ? cell.value.toString().length : 10;
        if (column.width < maxLength) {
          column.width = maxLength < 15 ? 15 : maxLength; 
        }
      });
    });
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
      saveAs(blob, 'RFID List.xlsx');
    });
  }

  const handleDetailRfid = (rfidId) => {
    getRfid(rfidId);
    setShowDetail(true);
  }

  const handleEditRfid = (rfid) => {
    setEditRfidId(rfid.rfid_id);
    setEditAccessKey(rfid.access_key);
    setEditUser({});
    setShowDetail(false);
    setShowEdit(true);
  }

  return (
    <div id="user-groups">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Rfid'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">Card List</h3>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <form id="search" onSubmit={(e) => e.preventDefault()}>
                  <div className="input-group mb-0">
                    <input className="form-control" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                    <span className="btn btn-link"><i className="bi bi-search"/></span>
                  </div>
                </form>
                <form id="display" onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group ms-2 mb-0">
                    <select className="form-select" type="text" placeholder="Display..." value={display} onChange={(e) => setDisplay(e.target.value)}>
                      <option value="">All Status</option>
                      <option value="Available">Available</option>
                      <option value="Used">Used</option>
                    </select>
                  </div>
                </form>
                {rfids.length ? (
                  <p className="ms-2 mb-0">Total: {rfids.length}</p>
                ) : ''}
              </div>
              <div>
                <button className="btn btn-secondary" onClick={() => handleAddRfid()}>Add</button>
                <button className="btn btn-secondary ms-2" onClick={() => handleImportRfid()}>Import</button>
                <button className="btn btn-secondary ms-2" onClick={() => handleExportRfid()}>Export</button>
              </div>
            </div>
            <div className="table-responsive">
              {rfids.length > 0 ? (
                <table className="table table-border table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Key</th>
                      <th>Used By</th>
                      <th>Created On</th>
                      <th>Created By</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rfids.filter((rfid) => rfid.search.toLowerCase().includes(search.toLowerCase())).map((rfid, r) => (
                      <tr className="pointer" key={'access-log-' + r} onClick={() => handleDetailRfid(rfid.rfid_id)}>
                        <td>{r + 1}.</td>
                        <td>{rfid.access_key}</td>
                        <td>{rfid.taken_name}</td>
                        <td>{rfid.created_on ? formatDate(rfid.created_on) : ''}</td>
                        <td>{rfid.user_name}</td>
                        <td>{rfid.status === 'Available' ? <span className="badge text-bg-success">Available</span> : <span className="badge text-bg-info">Used</span>}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : ('No Data')}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add RFID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add" onSubmit={(e) => addRfid(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Key</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addAccessKey} onChange={(e) => setAddAccessKey(e.target.value)} required/>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>User</h5>
              <Select className="selection" options={users} value={addUser} onChange={(value) => setAddUser(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowAdd(false)} disabled={loadingAdd}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="add" disabled={loadingAdd}>{loadingAdd ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showImport} onHide={() => setShowImport(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Import RFID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="import" onSubmit={(e) => importRfid(e)}>
            <p className="mt-2">Download this <a href="/rfid-template.csv" download>CSV template</a> to input RFID keys</p>
            <div className="row align-items-center mt-3">
              <div className="col-md-4"><label>Choose File</label></div>
              <div className="col-md-8">
                <input className="form-control" type="file" accept=".csv" onChange={(e) => getImportFile(e.target.files[0])} required/>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowImport(false)} disabled={loadingImport}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="import" disabled={loadingImport}>{loadingImport ? "Loading..." : "Import"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>RFID Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Key</label></div>
              <div className="col-md-8">{rfid.access_key}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Created On</label></div>
              <div className="col-md-8">{rfid.created_on ? formatDate(rfid.created_on) : ''}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Created By</label></div>
              <div className="col-md-8">{rfid.user_name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Status</label></div>
              <div className="col-md-8">{rfid.status}</div>
            </div>
            <div className="form-group mt-3">
              <h5>User</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={user}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div className="">
              {rfid.taken_id ? (
                <span className="text-danger ms-2" onClick={() => revokeRfid()}>Revoke</span>
              ) : '' }
            </div>
            <div className="d-flex align-items-center">
              <span onClick={() => setShowDetail(false)}>Close</span>
              <button className="btn btn-secondary ms-3" onClick={() => handleEditRfid(rfid)}>Edit</button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit RFID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editRfid(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Key</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editAccessKey} onChange={(e) => setEditAccessKey(e.target.value)}/>
              </div>
            </div>
            {rfid.taken_id ? (
              <div className="form-group mt-3">
                <h5>User</h5>
                <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={user}/>
              </div>
            ) : (
              <div className="form-group mt-3">
                <h5>User</h5>
                <Select className="selection" options={users} value={editUser} onChange={(value) => setEditUser(value)}/>
              </div>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Rfid;
