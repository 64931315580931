import React, { useState, useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import { config } from "../Components/Config";
import { Modal } from "react-bootstrap";
import Select from "react-select";

const md5 = require('md5');

const Users = () => {
  const apiUrl = config.ENDPOINT;

  const [ userInfo, setUserInfo ] = useState({});
  const [ users, setUsers ] = useState([]);
  const [ places, setPlaces ] = useState([]);
  const [ groups, setGroups ] = useState([]);
  const [ doors, setDoors ] = useState([]);
  const [ userPlaces, setUserPlaces ] = useState([]);
  const [ userGroups, setUserGroups ] = useState([]);
  const [ userDoors, setUserDoors ] = useState([]);
  const [ userAccess, setUserAccess ] = useState([]);
  const [ user, setUser ] = useState({});
  const [ showAdd, setShowAdd ] = useState(false);
  const [ showDetail, setShowDetail ] = useState(false);
  const [ showEdit, setShowEdit ] = useState(false);
  const [ showAddAccess, setShowAddAccess ] = useState(false);
  const [ loadingAdd, setLoadingAdd ] = useState(false);
  const [ loadingEdit, setLoadingEdit ] = useState(false);
  const [ addName, setAddName ] = useState('');
  const [ addEmail, setAddEmail ] = useState('');
  const [ addPassword, setAddPassword ] = useState('');
  const [ addRole, setAddRole ] = useState('User');
  const [ addStatus, setAddStatus ] = useState('Active');
  const [ addUserPlaces, setAddUserPlaces ] = useState([]);
  const [ addUserGroups, setAddUserGroups ] = useState([]);
  const [ addUserDoors, setAddUserDoors ] = useState([]);
  const [ addUserAccess, setAddUserAccess ] = useState([]);
  const [ editUserId, setEditUserId ] = useState('');
  const [ editName, setEditName ] = useState('');
  const [ editEmail, setEditEmail ] = useState('');
  const [ editOrganizationUserId, setEditOrganizationUserId ] = useState('');
  const [ editRole, setEditRole ] = useState('User');
  const [ editStatus, setEditStatus ] = useState('Active');
  const [ search, setSearch ] = useState('');

  const randomKey = () => {
    const chars = '1234567890abcdefghijklmnopqrstuvwxyz';
    const digits = 16;
    let result = '';
    for (let i = 0; i < digits; i++) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  const accessList = [
    {value: 'App Button', label: 'App Button', key: randomKey()},
    {value: 'QR Code', label: 'QR Code', key: randomKey()},
    {value: 'RFID', label: 'RFID', key: ''},
    {value: 'Face', label: 'Face', key: ''}
  ];

  useEffect(() => {
    const userLogin = localStorage.getItem('userInfo');
    if (userLogin) {
      setUserInfo(JSON.parse(userLogin));
    } else {
      window.location.replace('/');
    }
  }, []);

  useEffect(() => {
    if (userInfo.organizationId) {
      getUsers();
      getPlaces();
      getGroups();
      getDoors();
    }
  }, [userInfo]);

  const getUsers = async () => {
    const fetchUsers = await fetch(apiUrl + 'list/users', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataUsers = await fetchUsers.json();
    if (dataUsers.status === 200) {
      let listUsers = [];
      for( let u in dataUsers.values) {
        dataUsers.values[u].search = dataUsers.values[u].name + " " + dataUsers.values[u].email + " " + dataUsers.values[u].role;
        listUsers.push(dataUsers.values[u]);
      }
      setUsers(listUsers);
    }
  }

  const getPlaces = async () => {
    const fetchPlaces = await fetch(apiUrl + 'list/places', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataPlaces = await fetchPlaces.json();
    if (dataPlaces.status === 200) {
      let valuePlaces = [];
      for (let g in dataPlaces.values) {
        valuePlaces.push({
          value: dataPlaces.values[g].place_id,
          label: dataPlaces.values[g].name
        });
      };
      setPlaces(valuePlaces);
    }
  }

  const getGroups = async () => {
    const fetchGroups = await fetch(apiUrl + 'list/groups', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataGroups = await fetchGroups.json();
    if (dataGroups.status === 200) {
      let valueGroups = [];
      for (let g in dataGroups.values) {
        valueGroups.push({
          value: dataGroups.values[g].group_id,
          label: dataGroups.values[g].name
        });
      };
      setGroups(valueGroups);
    }
  }

  const getDoors = async () => {
    const fetchDoors = await fetch(apiUrl + 'list/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId
      })
    });
    const dataDoors = await fetchDoors.json();
    if (dataDoors.status === 200) {
      let valueDoors = [];
      for (let d in dataDoors.values) {
        const doorIndex = valueDoors.findIndex(door => door.label === dataDoors.values[d].place_name);
        if (doorIndex >= 0) {
          valueDoors[doorIndex].options.push({
            value: dataDoors.values[d].door_id,
            label: dataDoors.values[d].name,
          });
        } else {
          valueDoors.push({
            label: dataDoors.values[d].place_name,
            options: [
              {
                value: dataDoors.values[d].door_id,
                label: dataDoors.values[d].name,
              }
            ]
          });
        }
      };
      setDoors(valueDoors);
    }
  }

  const getUser = async (userId) => {
    const fetchUser = await fetch(apiUrl + 'get/user', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userId
      })
    });
    const dataUser = await fetchUser.json();
    if (dataUser.status === 200) {
      setUser(dataUser.values);
    }
  }

  const getUserPlaces = async (userId) => {
    const fetchUserPlaces = await fetch(apiUrl + 'get/user/places', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userId
      })
    });
    const dataUserPlaces = await fetchUserPlaces.json();
    if (dataUserPlaces.status === 200) {
      let valueUserPlaces = [];
      for (let up in dataUserPlaces.values) {
        valueUserPlaces.push({
          value: dataUserPlaces.values[up].place_id,
          label: dataUserPlaces.values[up].name,
        });
      };
      setUserPlaces(valueUserPlaces);
    }
  }

  const getUserGroups = async (userId) => {
    const fetchUserGroups = await fetch(apiUrl + 'get/user/groups', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userId
      })
    });
    const dataUserGroups = await fetchUserGroups.json();
    if (dataUserGroups.status === 200) {
      let valueUserGroups = [];
      for (let ug in dataUserGroups.values) {
        valueUserGroups.push({
          value: dataUserGroups.values[ug].group_id,
          label: dataUserGroups.values[ug].name,
        });
      };
      setUserGroups(valueUserGroups);
    }
  }

  const getUserDoors = async (userId) => {
    const fetchUserDoors = await fetch(apiUrl + 'get/user/doors', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        userId: userId
      })
    });
    const dataUserDoors = await fetchUserDoors.json();
    if (dataUserDoors.status === 200) {
      let valueUserDoors = [];
      for (let ud in dataUserDoors.values) {
        valueUserDoors.push({
          value: dataUserDoors.values[ud].door_id,
          label: dataUserDoors.values[ud].name + " (" + dataUserDoors.values[ud].place_name + ")",
        });
      };
      setUserDoors(valueUserDoors);
    }
  }

  const getUserAccess = async (userId) => {
    const fetchUserAccess = await fetch(apiUrl + 'get/user/access', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        userId: userId
      })
    });
    const dataUserAccess = await fetchUserAccess.json();
    if (dataUserAccess.status === 200) {
      let valueUserAccess = [];
      for (let ua in dataUserAccess.values) {
        valueUserAccess.push({
          value: dataUserAccess.values[ua].access_type,
          label: dataUserAccess.values[ua].access_type,
          key: dataUserAccess.values[ua].access_key,
        });
      };
      setUserAccess(valueUserAccess);
    }
  }

  const addUser = async (e) => {
    e.preventDefault();
    setLoadingAdd(true);
    const fetchUser = await fetch(apiUrl + 'add/user', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        organizationId: userInfo.organizationId,
        name: addName,
        email: addEmail,
        password: md5(addEmail),
        role: addRole,
        status: addStatus,
        placeUsers: addUserPlaces,
        groupUsers: addUserGroups,
        doorUsers: addUserDoors,
        userAccess: addUserAccess
      })
    });
    const dataUser = await fetchUser.json();
    if (dataUser.status === 200) {
      window.location.reload();
    }
  }

  const editUser = async (e) => {
    e.preventDefault();
    setLoadingEdit(true);
    const fetchUser = await fetch(apiUrl + 'edit/user', {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
			body: JSON.stringify({
        userId: editUserId,
        name: editName,
        email: editEmail,
        organizationUserId: editOrganizationUserId,
        role: editRole,
        status: editStatus,
        placeUsers: userPlaces,
        groupUsers: userGroups,
        doorUsers: userDoors,
        userAccess: userAccess
      })
    });
    const dataUser = await fetchUser.json();
    if (dataUser.status === 200) {
      window.location.reload();
    }
  }

  const handleAddUser = () => {
    setShowAdd(true);
  }

  const handleDetailUser = (userId) => {
    getUser(userId);
    getUserPlaces(userId);
    getUserGroups(userId);
    getUserDoors(userId);
    getUserAccess(userId);
    setShowDetail(true);
  }

  const handleEditUser = (user) => {
    setEditUserId(user.user_id);
    setEditName(user.name);
    setEditEmail(user.email);
    setEditOrganizationUserId(user.organization_user_id);
    setEditRole(user.role);
    setEditStatus(user.status);
    setShowDetail(false);
    setShowEdit(true);
  }

  return (
    <div id="user-users">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 p-0">
            <Sidebar userInfo={userInfo} active={'Users'}/>
          </div>
          <div className="col-md-9 p-5 main">
            <h3 className="mb-4">User List</h3>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <form id="search" onSubmit={(e) => e.preventDefault()}>
                <div className="input-group mb-0">
                  <input className="form-control" type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)}/>
                  <span className="btn btn-link"><i className="bi bi-search"/></span>
                </div>
              </form>
              <div>
                <button className="btn btn-secondary" onClick={() => handleAddUser()}>Add</button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-border table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Places</th>
                    <th>Groups</th>
                    <th>Doors</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {users.filter((user) => user.search.toLowerCase().includes(search.toLowerCase())).map((user, u) => (
                    <tr className="pointer" key={'user-' + u} onClick={() => handleDetailUser(user.user_id)}>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.places ? user.places : 0}</td>
                      <td>{user.groups ? user.groups : 0}</td>
                      <td>{user.doors ? user.doors : 0}</td>
                      <td>{user.role}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="add" onSubmit={(e) => addUser(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={addName} onChange={(e) => setAddName(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Email</label></div>
              <div className="col-md-8">
                <input className="form-control" type="email" value={addEmail} onChange={(e) => setAddEmail(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Password</label></div>
              <div className="col-md-8">
                <input className="form-control" type="password" value={addPassword} onChange={(e) => setAddPassword(e.target.value)} required/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Role</label></div>
              <div className="col-md-8">
                <select className="form-select" value={addRole} onChange={(e) => setAddRole(e.target.value)} required>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Status</label></div>
              <div className="col-md-8">
                <select className="form-select" value={addStatus} onChange={(e) => setAddStatus(e.target.value)} required>
                  <option value="Active">Active</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Places</h5>
              <Select className="selection" options={places} isMulti={true} closeMenuOnSelect={false} value={addUserPlaces} onChange={(value) => setAddUserPlaces(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Groups</h5>
              <Select className="selection" options={groups} isMulti={true} closeMenuOnSelect={false} value={addUserGroups} onChange={(value) => setAddUserGroups(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Doors</h5>
              <Select className="selection" options={doors} isMulti={true} closeMenuOnSelect={false} value={addUserDoors} onChange={(value) => setAddUserDoors(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Access</h5>
              <Select className="selection" options={accessList} isMulti={true} closeMenuOnSelect={false} value={addUserAccess} onChange={(value) => setAddUserAccess(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowAdd(false)} disabled={loadingAdd}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="add" disabled={loadingAdd}>{loadingAdd ? "Loading..." : "Save"}</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDetail} onHide={() => setShowDetail(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="detail" onSubmit={(e) => e.preventDefault()}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">{user.name}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Email</label></div>
              <div className="col-md-8">{user.email}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Role</label></div>
              <div className="col-md-8">{user.role}</div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Status</label></div>
              <div className="col-md-8">{user.status}</div>
            </div>
            <div className="form-group mt-3">
              <h5>Places</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={userPlaces}/>
            </div>
            <div className="form-group mt-2">
              <h5>Groups</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={userGroups}/>
            </div>
            <div className="form-group mt-2">
              <h5>Doors</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={userDoors}/>
            </div>
            <div className="form-group mt-3">
              <h5>Access</h5>
              <Select className="selection" isDisabled={true} isMulti={true} placeholder={"No Data"} value={userAccess}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowDetail(false)}>Close</span>
          <button className="btn btn-secondary ms-3" onClick={() => { handleEditUser(user); }}>Edit</button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="edit" onSubmit={(e) => editUser(e)}>
            <div className="row align-items-center">
              <div className="col-md-4"><label>Name</label></div>
              <div className="col-md-8">
                <input className="form-control" type="text" value={editName} onChange={(e) => setEditName(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Email</label></div>
              <div className="col-md-8">
                <input className="form-control" type="email" value={editEmail} onChange={(e) => setEditEmail(e.target.value)}/>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Role</label></div>
              <div className="col-md-8">
                <select className="form-select" value={editRole} onChange={(e) => setEditRole(e.target.value)}>
                  <option value="Admin">Admin</option>
                  <option value="User">User</option>
                </select>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-md-4"><label>Status</label></div>
              <div className="col-md-8">
                <select className="form-select" value={editStatus} onChange={(e) => setEditStatus(e.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
            </div>
            <div className="form-group mt-3">
              <h5>Places</h5>
              <Select className="selection" options={places} isMulti={true} value={userPlaces} closeMenuOnSelect={false} onChange={(value) => setUserPlaces(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Groups</h5>
              <Select className="selection" options={groups} isMulti={true} value={userGroups} closeMenuOnSelect={false} onChange={(value) => setUserGroups(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Doors</h5>
              <Select className="selection" options={doors} isMulti={true} value={userDoors} closeMenuOnSelect={false} onChange={(value) => setUserDoors(value)}/>
            </div>
            <div className="form-group mt-2">
              <h5>Access</h5>
              <Select className="selection" options={accessList} isMulti={true} closeMenuOnSelect={false} value={userAccess} onChange={(value) => setUserAccess(value)}/>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <span onClick={() => setShowEdit(false)} disabled={loadingEdit}>Cancel</span>
          <button className="btn btn-primary ms-3" type="submit" form="edit" disabled={loadingEdit}>{loadingEdit ? "Loading..." : "Update"}</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users;
