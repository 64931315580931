var config; 
if (process.env.REACT_APP_ENV === "DEV") { //DEV
  config = {
    ENDPOINT: "http://localhost:3001/api/",
    TRIGGERURL: "https://face-recognition-encoding-final-724973773120.asia-southeast2.run.app/image/encode",
    TRIGGERAUTH: "ef229daa-d058-4dd4-9c93-24761842aec4",
    PINGAUTH: "plnY6GfdQ0TDZlYInvv7mluRLrsH2xs6Stc3Oao6wbgvCIXKjfRJKlCKWAHGFzCSNnzGOhoGkMBLWWM3LUXf3mcJmlTBEGzKUomLWBtejiA67zR3OSkOTL1fg691Wllw",
  }
} else { //PROD
  config = { 
    ENDPOINT: "https://ps-door-access.run.place/api/",
    TRIGGERURL: "https://face-recognition-encoding-final-724973773120.asia-southeast2.run.app/image/encode",
    TRIGGERAUTH: "ef229daa-d058-4dd4-9c93-24761842aec4",
    PINGAUTH: "plnY6GfdQ0TDZlYInvv7mluRLrsH2xs6Stc3Oao6wbgvCIXKjfRJKlCKWAHGFzCSNnzGOhoGkMBLWWM3LUXf3mcJmlTBEGzKUomLWBtejiA67zR3OSkOTL1fg691Wllw",
  }
}

export { config };